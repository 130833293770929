@import "../../Styles/variables";
.cardwrapper-section {
  position: relative;
  &.ebid-card {
    border: 1px solid $borderColor;
    border-radius: 4px;
    padding-bottom: 0;
  }
  &.enotice-card {
    border: 1px solid $borderColor;
    margin: 24px 0;
    border-radius: 4px;
    padding-bottom: 0;
  }

  .slide-wrapper {
    .mdi-close-image-slider {
      color: #dc3545;
      position: fixed;
      top: 0;
      right: 0;
      margin-bottom: 400px;
      z-index: 9999;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  }
  .side-ebid-section {
    .side-ebid-title-section {
      padding: 12px;
      &.primary {
        background: $primaryColor;
      }
      &.secondary {
        background: $secondaryColor;
      }
      .title {
        font-weight: 600;
        color: #fff;
      }
    }
    .side-ebid-body {
      height: auto;
      max-height: 520px;
      overflow: hidden;
      .side-ebid-content-section {
        .side-tender-body {
          .tender-title {
            font-size: 12px;
          }
          .sub-title {
            font-size: 10px;
          }
        }
        .tender-card {
          display: flex;
          gap: 12px;
          padding: 8px;
          border-bottom: 1px solid $borderColor;
          background: #fff;
          img {
            width: 50px;
            height: 50px;
            border: 1px solid $borderColor;
            object-fit: contain;
            border-radius: 4px;
          }
          .tender-title {
            margin-top: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            margin-bottom: 4px;
          }
          .sub-title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
}

.cardwrapper-section {
  position: relative;

  .slide-wrapper {
    .mdi-close {
      color: #dc3545;
      position: fixed;
      top: 0;
      right: 0;
      margin-bottom: 400px;
      z-index: 9999;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  }
}
.tender-card {
  .bid-before-first-section {
    display: flex;
    .ReactModal__Overlay {
      z-index: 1000 !important;
    }
  }
  .mg-right-8 {
    margin-right: 8px;
  }
  .work-tender-border {
    border-bottom: 1px solid #e5e5e5;
    margin: 5px 0;
  }
  .work-img {
    .img {
      width: 15px;
      height: 15px;
      margin-right: 6px;
      color: #ffad29;
    }

    .work-header {
      font-weight: 600;
      font-size: 14px;
      color: $fieldColor;
    }
  }
  .work-section {
    padding: 0px 20px;
    margin-bottom: 0 !important;
    .work-content {
      font-weight: 600;
      font-size: 14px;
      color: $titleColor;
      margin-top: 4px;
    }
  }
  .see-all-section {
    .see-all-text {
      color: $secondaryColor; 
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $secondaryColor; 
      }
      .mdi-chevron {
        width: 16px;
        height: 16px;
      }
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-bottom: 2px;
  }
  .p-section {
    padding: 10px 20px;
  }

  .tag-info {
    display: flex;
    align-items: center;

    .border-line {
      height: 12px;
    }

    .tender-bold {
      font-size: 13px;
    }

    .paragraph-tender {
      .tender-bold {
        margin-left: 0;
      }
    }
    img {
      width: 12px;
      margin-right: 8px;
    }

    .budget-icon {
      width: 14px;
      height: 8px;
    }

    .map-icon {
      width: 14px;
      height: 14px;
    }

    .tender-contents {
      font-size: 14px;
      font-weight: 600;
      color: $inputBorder;
    }
  }

  .tid-content {
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.07em;

    .tender-header {
      display: flex;
      letter-spacing: 0.07em;
    }
  }

  .sub-title {
    margin-top: 6px;
  }

  .border-line {
    border-right: 1px solid $borderColor;
    margin-left: 12px;
    margin-right: 14px;
    height: 12px;
    border-width: 2px;
  }

  .tender-title {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: $secondaryColor;
    text-align: initial;
    letter-spacing: 0.07em;
    margin-top: 10px;
    cursor: pointer;
  }

  .tender-border {
    border-bottom: 1px solid $outerlineColor;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .days-remain {
    display: flex;
    align-items: center;
  }

  .border-line {
    border-right: 1px solid $contentColor;
    height: 12px;
    border-width: 2px;
  }

  .days-count {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.07em;
    background: rgba(39, 96, 216, 0.1);
    color: $secondaryColor;
    padding: 5px 12px;
    margin-left: 10px;
    border-radius: 5px;
    color: $primaryColor;
  }

  .media-icon {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    img {
      width: 20px;
      height: 19px;
      margin-right: 16px;
    }
    .border-linked {
      margin-left: 0;
    }
  }
  .btn-combination {
    display: flex;
    justify-content: space-around;
  }

  .search-btn {
    padding: 6px 12px !important;
  }

  .btn-show {
    display: flex;
    color: $white;
    padding: 6px 12px;
    border: unset !important;
    img {
      width: 12px;
      margin-right: 8px;
    }

    .show-bid {
      background-color: $secondaryColor;
      border: 1px solid;
      padding: 6px 12px;
    }
  }

  .btn-bid {
    display: flex;
    color: $white;

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    .show-bid {
      background-color: $white;
      color: $white;
      border: 1px solid $secondaryColor;
      padding: 6px 12px;
    }
  }
  .tender_number-content {
    justify-content: space-between;
    display: flex;
    .tender_number-no {
      font-size: 12px;
      margin-right: 8px;
    }

    .tender-document-data {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  .tid-content {
    display: unset !important;
  }
  .tender-header {
    display: flex;
    align-items: center;
  }
  .card-wrapper {
    display: flex;
    justify-content: space-between;

    .bid-before {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .tid-no {
    font-weight: 600;
    font-size: 12px;
    color: $grayColor;
  }
}

.tender-in-between-section {
  margin-bottom: 14px;
  .tender-in-between-block {
    height: 187px;
    // margin: 8px 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.time-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $grayColor;
  margin-bottom: 2px;
  text-align: initial;
  margin-left: 15px;
}
.close-icon {
  text-align: right;
  cursor: pointer;
  .mdi-close {
    height: 18px;
    width: 18px;
    color: $inputBorder;
  }
}
@media (max-width: 768px) {
  .search-btn {
    margin: unset;
  }
  .tender-card {
    .btn-combination {
      margin-left: 14px;
    }
    .search-btn {
      white-space: nowrap;
      font-size: 12px;
    }
    .days-count {
      white-space: nowrap;
    }
    .tender-contents {
      white-space: nowrap;
    }
    .btn-show {
      padding: 6px 0px;
      .show-bid {
        font-size: 12px;
        white-space: nowrap;
      }
    }
    .media-icon {
      img {
        margin-right: 12px;
      }
    }
    .tender-title {
      font-size: 13px;
    }
  }
}
@media (max-width: 480px) {
  .tender-card {
    .bid-before-first-section {
      display: unset;
    }
    .media-icon {
      justify-content: flex-end;
    }
    .border-line {
      border: none;
      margin-right: unset !important;
    }
  }
  .card-wrapper {
    flex-wrap: unset;
  }
  .d-flex {
    justify-content: space-between;
  }
  .bid-before-first-section {
    float: right;
  }
  .tag-info {
    margin-bottom: 8px;
  }
  .tender_number-content {
    display: unset !important;
  }
  .tender-bold {
    display: unset !important;
  }
  .tender-header {
    display: unset !important;
  }
  .card-section {
    .tender-title {
      font-size: 14px !important;
    }
  }
  .tender-card {
    .card {
      position: unset;
    }
    .tender-bold {
      display: flex;
    }
    .tid_no {
      margin-left: 14px;
      display: flex;
    }
    .card-wrapper {
      display: unset;
    }
  }
}
@media (max-width: 480px) {
  .tender-card {
    .tid-number {
      margin: 12px 0px 0px 21px;
    }
    .tid-content {
      display: block;
    }
    .p-section {
      padding: 10px 10px 5px 10px;
    }
  }

  @media (max-width: 376px) {
    .bidbefore-section {
      .btn-combination {
        display: flex;
      }
    }
    .tender_number-content {
      display: flex;
      flex-direction: column;
    }
    .tender_number-no {
      margin-top: 12px;
      margin-right: 6px;
    }
    .tender_number-no {
      display: flex;
    }
    .tender-bold {
      margin-top: 1px;
    }
    .days-remain {
      .days-count {
        margin-left: 62px;
      }
    }
    .card-wrapper {
      .media-icon {
        padding: 12px;
      }
      .bidbefore-section {
        display: unset !important   ;
      }
    }
  }
}
